html,
body {
  height: 100%;
}

img {
  width: 100%;
  max-width: 100%;
  height: auto;
}

button {
  letter-spacing: inherit;
}

b,
strong {
  @apply font-medium;
}

svg {
  fill: currentColor;
}
