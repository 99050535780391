@font-face {
  font-family: 'FrutigerNext';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url('../../../assets/fonts/FrutigerNextLTW1G-Light.woff') format('woff');
}

@font-face {
  font-family: 'FrutigerNext';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../../../assets/fonts/FrutigerNextLTW1G-Regular.woff')
    format('woff');
}

@font-face {
  font-family: 'FrutigerNext';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('../../../assets/fonts/FrutigerNextLTW1G-Medium.woff') format('woff');
}
