.net-table {
  @apply min-w-full divide-y divide-gray-300 table-auto border-collapse border-b border-b-gray-200 text-gray-900 -mx-4;

  tbody {
    @apply divide-y divide-gray-200;
  }

  th {
    @apply py-3.5 px-4 text-left text-sm font-normal;
  }

  td {
    @apply py-8 px-4 align-top;
  }
}
