input,
button {
  @apply appearance-none;
}

input:focus,
button:focus {
  @apply outline-none;
}

button {
  font-weight: inherit;
}

/* Remove webkit "clear search input"-x-icon  */
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input[type='time']::-webkit-calendar-picker-indicator {
  background: none;
  display: none;
}

input[type='time']::-webkit-clear-button {
  display: none;
}

input:required {
  box-shadow: none;
}
input:invalid {
  box-shadow: none;
}

::-webkit-input-placeholder {
  opacity: 1;
}
::-moz-placeholder {
  opacity: 1;
}
:-ms-input-placeholder {
  opacity: 1;
}
