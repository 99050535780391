.link {
  @apply relative cursor-pointer;
  $link-offset: 3px;
  $link-height: 1px;

  &::after,
  &::before {
    @apply bottom-[-#{$link-offset}] h-[#{$link-height}] absolute left-0 w-full content-[''];
  }

  &::before {
    @apply bg-gray-300;
  }

  &::after {
    @apply origin-left scale-x-0 bg-black transition duration-300;
  }

  &:hover::after,
  &:focus::after {
    @apply scale-x-100;
  }
}
