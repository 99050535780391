.editor-content {
  ol {
    @apply ml-5 list-decimal;

    li {
      @apply mb-2 pl-2;
    }
  }

  ul {
    li {
      @apply relative ml-4 mb-2;

      &:before {
        @apply absolute -ml-4 mr-2 text-black;
        content: '\2022';
      }
    }
  }

  a,
  .a {
    @apply link;

    &:hover {
      @apply border-current;
    }
  }

  p {
    @apply mb-3;
  }

  iframe {
    @apply max-w-full;
  }

  > *:first-child {
    @apply mt-0;
  }

  > *:last-child {
    @apply mb-0;
  }

  b,
  strong {
    /* font-weight: 700; */
    font-weight: 500;
  }

  img {
    width: auto;
  }
}
